import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["CraftInput","MyRecipesBadge"] */ "/vercel/path0/src/app/@header/components.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/@header/prompt-highlight.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainMenu"] */ "/vercel/path0/src/app/menu/components.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/display/badge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Card","CardHeader","CardContent"] */ "/vercel/path0/src/components/display/card.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/highlight.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/input/button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Popover","PopoverTrigger","PopoverContent"] */ "/vercel/path0/src/components/layout/popover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SheetTrigger","SheetOverlay","SheetContent"] */ "/vercel/path0/src/components/layout/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HasClaimedProfileName"] */ "/vercel/path0/src/components/logic/has-claimed-profile-name.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/navigation/navigation-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileName"] */ "/vercel/path0/src/components/strings/profile-name.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuSheet"] */ "/vercel/path0/src/modules/main-menu/menu-sheet.tsx");
